/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_c8g4dQYQD",
    "aws_user_pools_web_client_id": "2578cv8knav8gjfrq4vt94fc6l",
    "oauth": {},
    "aws_content_delivery_bucket": "dg3whuqs7p4uukp3egxj.clickdefense.cc-master",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d2uo59nkpt3cgl.cloudfront.net"
};


export default awsmobile;
