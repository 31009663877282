import Vue from 'vue'
import FeatherIcon from './components/FeatherIcon.vue'
import VxCard from './components/vx-card/VxCard.vue'
import BootstrapVue from 'bootstrap-vue'
import VueMomentLib from 'vue-moment-lib'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxCard.name, VxCard)
Vue.use(BootstrapVue)
Vue.use(VueMomentLib)

Vue.moment.locale('es')