import Vue from 'vue'
import Router from 'vue-router'
import Amplify from 'aws-amplify';
import AmplifyStore from './store';
import { AmplifyEventBus } from 'aws-amplify-vue'

Vue.use(Router)

let user

AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut') {
    user = null
    AmplifyStore.commit('setUser', null);
    router.push({ path: '/auth' })
  } else if (state === 'signedIn') {
    user = await getUser()
    router.push({ path: '/google-accounts' })
  }
});

function getUser() {
  return Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      AmplifyStore.commit('setUser', data)
      return data
    }
  }).catch(() => {
    AmplifyStore.commit('setUser', null)
    return null
  })
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/auth',
      name: 'Authenticator',
      component: () => import('@/views/pages/Auth.vue'),
      meta: {
        rule: 'isUserAcl'
      }
    },
    {
      path: '/signup/:actionLnk?',
      name: 'Signup',
      component: () => import('@/views/pages/Signup.vue'),
      meta: {
        rule: 'isUserAcl'
      }
    },
    {
      // MAIN LAYOUT ROUTES
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          name: 'statistics',
          component: () => import('./views/Statistics.vue'),
          meta: {
            pageTitle: 'Estadísticas',
            requiresAuth: true,
            rule: 'isUserAcl'
          }
        },
        {
          path: '/traffic',
          name: 'traffic',
          component: () => import('./views/Traffic.vue'),
          meta: {
            pageTitle: 'Tráfico',
            requiresAuth: true,
            rule: 'isUserAcl'
          }
        },
        {
          path: '/google-accounts',
          name: 'googleAccounts',
          component: () => import('@/views/GoogleAccounts.vue'),
          meta: {
            pageTitle: 'Cuentas',
            requiresAuth: true,
            rule: 'isUserAcl'
          }
        },
        {
          path: '/user-rules',
          name: 'user-rules',
          component: () => import('@/views/UserRules.vue'),
          meta: {
            pageTitle: 'Opciones',
            requiresAuth: true,
            rule: 'isUserAcl'
          },
        },
        {
          path: '/admin-page',
          name: 'admin',
          component: () => import('@/views/Admin.vue'),
          meta: {
            pageTitle: 'Admin',
            requiresAuth: true,
            rule: 'isAdminAcl'
          },
        },
        {
          path: '/admin-trans',
          name: 'admin-trans',
          component: () => import('@/views/AdminTrans.vue'),
          meta: {
            pageTitle: 'Transacciones Admin',
            requiresAuth: true,
            rule: 'isAdminAcl'
          },
        },
        {
          path: '/blacklist',
          name: 'blacklist',
          component: () => import('./views/Blacklist.vue'),
          meta: {
            pageTitle: 'Lista Negra',
            requiresAuth: true,
            rule: 'isUserAcl'
          }
        },
        {
          path: '/whitelist',
          name: 'whitelist',
          component: () => import('./views/Whitelist.vue'),
          meta: {
            pageTitle: 'Lista Blanca',
            requiresAuth: true,
            rule: 'isAdminAcl' /* isUserAcl */
          }
        },
        {
          path: '/clickstatus',
          name: 'clickstatus',
          component: () => import('./views/ClickStatus.vue'),
          meta: {
            pageTitle: 'Click Status',
            requiresAuth: true,
            rule: 'isAdminAcl'
          }
        },
        {
          path: '/installation',
          name: 'installation',
          component: () => import('./views/Installation.vue'),
          meta: {
            pageTitle: 'Instalación',
            requiresAuth: true,
            rule: 'isUserAcl'
          }
        },
        {
          path: '/guest',
          name: 'guest',
          component: () => import('./views/Guest.vue'),
          meta: {
            pageTitle: 'Referidos',
            requiresAuth: true,
            rule: 'isRefererAcl'
          }
        },
        {
          path: '/sales',
          name: 'sales',
          component: () => import('./views/Sales.vue'),
          meta: {
            pageTitle: 'Ventas',
            requiresAuth: true,
            rule: 'isSalesAcl'
          }
        },
      ]
    },
    {
      // PROFILE LAYOUT ROUTES
      path: '',
      component: () => import('./layouts/main/Profile.vue'),
      children: [
        {
          path: '/settings',
          name: 'settings',
          component: () => import('@/views/Settings.vue'),
          meta: {
            pageTitle: 'Ajustes',
            requiresAuth: true,
            rule: 'isUserAcl'
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/Profile.vue'),
          meta: {
            pageTitle: 'Perfil',
            requiresAuth: true,
            rule: 'isAdminAcl'
          },
        },
        {
          path: '/payment',
          name: 'payment',
          component: () => import('./views/Payment.vue'),
          meta: {
            pageTitle: 'Suscripción',
            requiresAuth: true,
            rule: 'isUserAcl'
          }
        },
      ]
    },
    // FULL PAGE LAYOUTS
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/pages/error-404',
          name: 'pageError404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/hp18u25ufj4vmrv2qr0f2ukgu86o30zvbfnie8n3',
          name: 'linkVerify',
          component: () => import('@/views/pages/LinkVerify.vue'),
          meta: {
            requiresAuth: false,
            rule: 'isUserAcl'
          },
        },
        {
          path: '/aigpdr2gp69l1llom32r1sdj1d2tro9i3no0ywuw1eya3ntdc3fr1c3pwaqfa77r9ckuh2ljwyh1xr8o45/:wjjn',
          name: 'externalActions',
          component: () => import('@/views/pages/ExternalActions.vue'),
          meta: {
            requiresAuth: false,
            rule: 'isUserAcl'
          },
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})


router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    user = await getUser()
    if (!user) {
      return next({
        path: '/auth',
        query: {
          redirect: to.fullPath,
        },
      })
    }
    return next()
  }
  return next()
})

export default router
