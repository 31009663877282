const actions = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    updateSidebarWidth({ commit }, width) {
        commit('UPDATE_SIDEBAR_WIDTH', width);
    },
    updateI18nLocale({ commit }, locale) {
        commit('UPDATE_I18N_LOCALE', locale);
    },
    toggleContentOverlay({ commit }) {
        commit('TOGGLE_CONTENT_OVERLAY');
    },
    updateTheme({ commit }, val) {
        commit('UPDATE_THEME', val);
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
        commit('UPDATE_STARRED_PAGE', payload)
    },

    //  The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // User
    updateUserData({ commit }, data) {
        commit('UPDATE_USER_DATA', data);
        commit('USER_UPDATED', true);
    },
    updateUserId({ commit }, data) {
        commit('UPDATE_USER_ID', data);
    },

    // Accounts
    updateAccounts({ commit }, data) {
        commit('UPDATE_ACCOUNTS', data);
    },

    // Accounts
    setDefaultAccount({ commit }, data) {
        commit('UPDATE_DEFAULT_ACCOUNT', data);
    },

    // Accounts
    updateAccountMonitoring({ commit }, data) {
        commit('UPDATE_ACCOUNT_MONITORING', data);
    },

    // Accounts
    updateAccountProtecting({ commit }, data) {
        commit('UPDATE_ACCOUNT_PROTECTING', data);
    },

    // Accounts
    setAllAccountsMonitoring({ commit }, data) {
        commit('SET_ALL_ACCOUNTS_MONITORING', data);
    },

    // Accounts
    setAllAccountsProtecting({ commit }, data) {
        commit('SET_ALL_ACCOUNTS_PROTECTING', data);
    },

    // Accounts
    updateMainAccount({ commit }, data) {
        commit('UPDATE_MAIN_ACCOUNT', data);
        //commit('UPDATE_MAIN_ACCOUNT_NAME', data);
    },

    // Accounts
    updateNotifications({ commit }, data) {
        commit('UPDATE_NOTIFICATIONS', data);
    },

    // Accounts
    updateTrialLimit({ commit }, data) {
        commit('UPDATE_TRIAL_LIMIT', data);
    },

    updateSetupCompleted({ commit }, data) {
        commit('UPDATE_SETUP_COMPLETED', data);
    },

    // User Rules
    updateUserRules({ commit }, data) {
        commit('UPDATE_USER_RULES', data);
    },

    updateUserRule({ commit }, data) {
        commit('UPDATE_USER_RULE', data);
    },

    removeUserRule({ commit }, data) {
        commit('REMOVE_USER_RULE', data);
    },

    enableUserRule({ commit }, data) {
        commit('ENABLE_USER_RULE', data);
    },

    disableUserRule({ commit }, data) {
        commit('DISABLE_USER_RULE', data);
    },

    // Stats
    storeStatsRaw({ commit }, data) {
        commit('STORE_STATS_RAW', data);
    },

    updateAdmin({ commit }, data) {
        commit('UPDATE_ADMIN', data);
    },

    // User
    setUserData({ commit }, data) {
        commit('USER_UPDATED', data);
    },
    emptyStats({ commit }, data) {
        commit('EMPTY_STATS', data);
    },
    emptyUser({ commit }, data) {
        commit('EMPTY_USER', data);
    },
    updateUserAccounts({ commit }, data) {
        commit('UPDATE_USER_ACCOUNTS', data);
    },
    updateCreditCardValid({ commit }, data) {
        commit('UPDATE_CREDIT_CARD_VALID', data);
    },
    updateBillInformation({ commit }, data) {
        commit('UPDATE_BILL_INFORMATION', data)
    },
    updateBillData({ commit }, data) {
        commit('UPDATE_BILL_DATA', data)
    },
    updateNotiEnable({ commit }, data) {
        commit('UPDATE_NOTI_ENABLE', data)
    },
    updateProtectingFlag({ commit }, data) {
        commit('UPDATE_PROTECTING_FLAG', data)
    },
    setEmail({ commit }, data) {
        commit('SET_EMAIL', data)
    },
    updateIntegrationStep({ commit }, data) {
        commit('UPDATE_INTEGRATION_STEP', data)
    },
    // Sales User
    updateSalesData({ commit }, data) {
        commit('UPDATE_SALES_DATA', data)
    },
    // Initial Modal
    updateTourEnabled({ commit }, data) {
        commit('UPDATE_TOUR_ENABLED', data)
    },
}

export default actions
