var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.showCookies ? _vm.cookiesVisible : "", _vm.cookiesContainer]
    },
    [
      _c("div", { staticClass: "gdpr-cookie-layer__container" }, [
        _c(
          "p",
          { class: [_vm.moreInfo ? _vm.extendedInfo : "", _vm.infoClass] },
          [
            _vm._v(
              "Utilizamos cookies y tecnologías similares propias y de terceros, de\n      sesión o persistentes, para hacer funcionar de manera segura nuestra página web y personalizar su\n      contenido. Igualmente, utilizamos cookies para medir y obtener datos de la navegación que realizas y\n      para ajustar la publicidad a tus gustos y preferencias. Puedes configurar y aceptar el uso de\n      cookies a continuación. Asimismo, puedes ver cómo modificar tus opciones de consentimiento en cualquier\n      momento visitando nuestra "
            ),
            _c("b", [_vm._v("Política de Cookies")]),
            _vm._v(", haciendo clic "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.clickdefense.io/cookies.html",
                  target: "_blank"
                }
              },
              [_vm._v("aquí")]
            ),
            _vm._v(".")
          ]
        ),
        _c("div", { staticClass: "gdpr-cookie-layer__lower-section" }, [
          _c("div", { staticClass: "gdpr-cookie-layer__checkboxes" }, [
            _vm._m(0),
            _c(
              "button",
              {
                staticClass:
                  "gdpr-cookie-layer__btn gdpr-cookie-layer__btn--details",
                on: {
                  click: function($event) {
                    return _vm.showInfo()
                  }
                }
              },
              [
                _vm._v("Más\n          información"),
                _c("span", { class: [_vm.moreInfo ? "open" : ""] })
              ]
            )
          ]),
          _c("div", { staticClass: "gdpr-cookie-layer__submit-buttons" }, [
            _c(
              "button",
              {
                staticClass:
                  "gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--all",
                on: {
                  click: function($event) {
                    return _vm.acceptAll()
                  }
                }
              },
              [_vm._v(" ✔\n          Aceptar\n          todo")]
            ),
            _c(
              "button",
              {
                staticClass:
                  "gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--selected",
                on: {
                  click: function($event) {
                    return _vm.saveCookies()
                  }
                }
              },
              [_vm._v("Guardar")]
            )
          ]),
          _c("div", { attrs: { id: "botonera2" } }, [
            _c("div", { staticClass: "gdpr-cookie-layer__submit-buttons" }, [
              _c(
                "button",
                {
                  staticClass:
                    "gdpr-cookie-layer__btn gdpr-cookie-layer__btn--details",
                  on: {
                    click: function($event) {
                      return _vm.showInfo()
                    }
                  }
                },
                [
                  _vm._v("Más\n            información"),
                  _c("span", { class: [_vm.moreInfo ? "open" : ""] })
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--selected"
                },
                [_vm._v("Guardar")]
              )
            ]),
            _c(
              "button",
              {
                staticClass:
                  "gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--all",
                on: {
                  click: function($event) {
                    return _vm.acceptAll()
                  }
                }
              },
              [_vm._v(" ✔\n          Aceptar\n          todo")]
            )
          ])
        ]),
        _c(
          "div",
          { class: [_vm.moreInfo ? _vm.detailShow : "", _vm.detailClass] },
          [_vm._m(1)]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "gdpr-cookie-layer__checkboxes__wrapper" },
      [
        _c(
          "div",
          {
            staticClass:
              "gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--functional"
          },
          [
            _c("input", {
              attrs: {
                type: "checkbox",
                name: "functional",
                value: "functional",
                checked: "",
                disabled: ""
              }
            }),
            _c("label", { attrs: { for: "functional" } }, [_vm._v("Técnicas")])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--comfort"
          },
          [
            _c("input", {
              attrs: {
                type: "checkbox",
                name: "comfort",
                value: "comfort",
                id: "comfort_id"
              }
            }),
            _c("label", { attrs: { for: "comfort_id" } }, [
              _vm._v("Personalización")
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--statistical"
          },
          [
            _c("input", {
              attrs: {
                type: "checkbox",
                name: "statistical",
                value: "statistical",
                id: "statistical_id"
              }
            }),
            _c("label", { attrs: { for: "statistical_id" } }, [
              _vm._v("Analíticas")
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--marketing"
          },
          [
            _c("input", {
              attrs: {
                type: "checkbox",
                name: "marketing",
                value: "marketing",
                id: "marketing_id"
              }
            }),
            _c("label", { attrs: { for: "marketing_id" } }, [
              _vm._v("Marketing")
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "contentspot",
        attrs: { "data-cms-id": "gdpr_cookie_layer-content" }
      },
      [
        _c(
          "div",
          {
            staticClass: "fg-container fg-initialized",
            staticStyle: { "min-height": "0px" },
            attrs: {
              id: "gdpr_cookielayer_page-content",
              identifier: "cookieInfoLayer"
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "fg-box bpx0 bpy0 bsx5 bsy1 mpx0 mpy0 msx4 msy1 spx0 spy0 ssx3 ssy1 fg-box-size-x-5",
                staticStyle: { top: "0px", height: "auto" }
              },
              [
                _c("div", { staticClass: "cms_html_container" }, [
                  _c("div", { staticClass: "cms_html_container" }, [
                    _c("h6", [_vm._v("Cookies técnicas")]),
                    _c("p", [
                      _vm._v(
                        "Las cookies técnicas son esenciales para que te puedas mover por la\n                  Plataforma y usar sus funciones. De manera general, estas cookies habilitan\n                  las diferentes funciones de la Plataforma, hacen que tu navegación sea más\n                  segura o aportan funcionalidades que son previamente solicitadas por ti. Al\n                  ser necesarias para el funcionamiento del sitio, estas cookies por defecto\n                  estarán activadas y no podrán denegarse."
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "ClickDefense puede utilizar cookies necesarias para guardar tu selección de\n                  las\n                  cookies en nuestro configurador, estabilizar tu navegación o hacerla más\n                  segura. "
                      )
                    ]),
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c("td", [_vm._v("Cookies técnicas")]),
                          _c("td", [_vm._v("Proveedor")]),
                          _c("td", [_vm._v("Finalidad")]),
                          _c("td", [_vm._v("Caducidad")])
                        ])
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("cdAccept")]),
                          _c("td", [_vm._v("ClickDefense")]),
                          _c("td", [
                            _vm._v(
                              "Contiene la configuración de consentimiento en la página."
                            )
                          ]),
                          _c("td", [_vm._v("12 meses")])
                        ])
                      ])
                    ]),
                    _c("h6", [_vm._v("Cookies de marketing")]),
                    _c("p", [
                      _vm._v(
                        "Estas cookies son aquellas que almacenan información del comportamiento de\n                  los usuarios obtenida a través de la observación continuada de sus hábitos\n                  de navegación, lo que permite desarrollar un perfil específico para mostrar\n                  publicidad en función de este o realizar determinados tratamientos y\n                  acciones publicitarias. De igual manera, a través del uso de estas Cookies\n                  podrás recibir anuncios de ClickDefense en sitios webs de terceros. Puedes\n                  aceptar o rechazar la instalación de estas Cookies."
                      )
                    ]),
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c("td", [_vm._v("Cookies de marketing")]),
                          _c("td", [_vm._v("Proveedor")]),
                          _c("td", [_vm._v("Finalidad")]),
                          _c("td", [_vm._v("Caducidad")])
                        ])
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("_fbp")]),
                          _c("td", [_vm._v("Facebook")]),
                          _c("td", [
                            _vm._v(
                              "Usado por Facebook para proporcionar servicios publicitarios y de\n                        análisis del sitio web."
                            )
                          ]),
                          _c("td", [_vm._v("3 meses")])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("fr")]),
                          _c("td", [_vm._v("Facebook")]),
                          _c("td", [
                            _vm._v(
                              "Usado por Facebook para mostrar, medir y mejorar la relevancia de\n                        anuncios."
                            )
                          ]),
                          _c("td", [_vm._v("3 meses")])
                        ])
                      ])
                    ]),
                    _c("h6", [_vm._v("Cookies analíticas")]),
                    _c("p", [
                      _vm._v(
                        "Estas Cookies permiten realizar un seguimiento y análisis del comportamiento\n                  de los usuarios, incluida la cuantificación de los impactos de los anuncios.\n                  La información recogida mediante este tipo de Cookies se utiliza en la\n                  medición de la actividad de la Plataforma con el fin de introducir mejoras\n                  en función del análisis de los datos de uso que hacen los usuarios del\n                  servicio. Puedes aceptar o rechazar la instalación de estas Cookies."
                      )
                    ]),
                    _c("table", [
                      _c("thead", [
                        _c("tr", [
                          _c("td", [_vm._v("Cookies analíticas")]),
                          _c("td", [_vm._v("Proveedor")]),
                          _c("td", [_vm._v("Finalidad")]),
                          _c("td", [_vm._v("Caducidad")])
                        ])
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("_gid")]),
                          _c("td", [_vm._v("Google Analytics")]),
                          _c("td", [
                            _vm._v("Usado para distinguir usuarios. ")
                          ]),
                          _c("td", [_vm._v("1 día")])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("_ga")]),
                          _c("td", [_vm._v("Google Analytics")]),
                          _c("td", [
                            _vm._v("Usado para distinguir usuarios. ")
                          ]),
                          _c("td", [_vm._v("24 meses")])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("_gat")]),
                          _c("td", [_vm._v("Google Analytics")]),
                          _c("td", [
                            _vm._v(
                              "Se utiliza para reducir la tasa de solicitudes."
                            )
                          ]),
                          _c("td", [_vm._v("1 minuto")])
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("_fgSesionId")]),
                          _c("td", [_vm._v("Froged")]),
                          _c("td", [
                            _vm._v(
                              "Usado para realizar seguimiento de cómo interactúa el usuario con el\n                        sitio web."
                            )
                          ]),
                          _c("td", [_vm._v("6 meses")])
                        ])
                      ])
                    ]),
                    _c("br"),
                    _c("p", [
                      _vm._v(
                        "Recuerda que puedes ver más información acerca de las cookies y cómo modificar tus\n                  opciones de consentimiento en cualquier momento visitando nuestra "
                      ),
                      _c("b", [
                        _vm._v("Política de\n                    Cookies")
                      ]),
                      _vm._v(", haciendo clic "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.clickdefense.io/cookies.html",
                            target: "_blank"
                          }
                        },
                        [_vm._v("aquí")]
                      ),
                      _vm._v(".")
                    ])
                  ])
                ])
              ]
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }