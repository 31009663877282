const moment = require('moment')

const getters = {
  trialDaysLeft: (state) => {
    if (state.user.trialEndDate < 0) {
      return 0
    } else {
      const dateEnd = moment(state.user.trialEndDate)
      const daysLeft = dateEnd.diff(moment(), 'days')
      if (daysLeft < 0) {
        return 0
      } else {
        return daysLeft
      }
    }
  },
  trialPercentLeft: (state) => {
    const dateEnd = moment(state.user.trialEndDate)
    const daysLeft = dateEnd.diff(moment(), 'days')
    const totalDays = state.trialTotalDays
    const percent = (daysLeft / totalDays) * 100
    return percent
  },
  trialColor: (state) => {
    const dateEnd = moment(state.user.trialEndDate)
    const daysLeft = dateEnd.diff(moment(), 'days')
    if (daysLeft > 10) {
      return 'success'
    } else if (daysLeft > 5) {
      return 'warning'
    } else {
      return 'danger'
    }
  },
  creditPercentLeft: (state) => {
    let percent = (state.user.credit * 100) / state.user.initialCredit
    if (percent < 0) {
      percent = 0
    }
    state.user.creditPercent = percent
    return percent
  },
  creditColor: (state) => {
    const creditPercent = state.user.creditPercent
    if (creditPercent > 60) {
      return 'success'
    } else if (creditPercent > 33) {
      return 'warning'
    } else {
      return 'danger'
    }
  },
  mainAccountName: (state) => {
    return state.user.mainAccountName
  }
}

export default getters