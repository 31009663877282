const mutations = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    UPDATE_SIDEBAR_WIDTH(state, width) {
        state.sidebarWidth = width;
    },
    UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
        state.sidebarItemsMin = val;
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val;
    },
    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val;
    },
    TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
    UPDATE_THEME(state, val) {
        state.theme = val;
    },
    UPDATE_WINDOW_BREAKPOINT(state, val) {
        state.breakpoint = val;
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val;
    },
    UPDATE_STATUS_CHAT(state, value) {
        state.AppActiveUser.status = value;
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    UPDATE_STARRED_PAGE(state, payload) {
        // find item index in search list state
        const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)
        // update the main list
        state.navbarSearchAndPinList.data[index].highlightAction = payload.val;

        // if val is true add it to starred else remove
        if (payload.val) {
            state.starredPages.push(state.navbarSearchAndPinList.data[index])
        } else {
            // find item index from starred pages
            const index = state.starredPages.findIndex((item) => item.index == payload.index)
            // remove item using index
            state.starredPages.splice(index, 1);
        }
    },

    // The Navbar
    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10);
        state.starredPages = list.concat(starredPagesMore);
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10];
        const starredPagesLimited = state.starredPages.slice(0, 10);
        state.starredPages = starredPagesLimited.concat(list);

        state.starredPages.slice(0, 10).map((i) => {
            if (list.indexOf(i) > -1) downToUp = true
        })
        if (!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited);
        }
    },

    // User
    UPDATE_USER_DATA(state, value) {
        state.user = value;
    },
    USER_UPDATED(state, value) {
        state.userUpdated = value
    },
    UPDATE_USER_ID(state, value) {
        state.user.id = value.userId
    },

    // Accounts
    UPDATE_ACCOUNTS(state, value) {
        state.google.accounts = value;
    },

    // Accounts
    UPDATE_DEFAULT_ACCOUNT(state, value) {
        state.user.mainAccount = value.mainAccount;
    },

    // Accounts
    UPDATE_ACCOUNT_MONITORING(state, value) {
        for (let i = 0; i < state.google.accounts.length; i++) {
            if (state.google.accounts[i].id === value.gAccount) {
                state.google.accounts[i].monitoring = value.monitoring
                break
            }
        }
    },

    // Accounts
    UPDATE_ACCOUNT_PROTECTING(state, value) {
        for (let i = 0; i < state.google.accounts.length; i++) {
            if (state.google.accounts[i].id === value.gAccount) {
                state.google.accounts[i].protecting = value.protecting
                break
            }
        }
    },

    // Accounts
    SET_ALL_ACCOUNTS_MONITORING(state, value) {
        for (let i = 0; i < state.google.accounts.length; i++) {
            state.google.accounts[i].monitoring = value
        }
    },

    // Accounts
    SET_ALL_ACCOUNTS_PROTECTING(state, value) {
        for (let i = 0; i < state.google.accounts.length; i++) {
            state.google.accounts[i].protecting = value
        }
    },

    // Accounts
    UPDATE_MAIN_ACCOUNT_NAME(state, value) {
        if (value && value.name) {
            state.user.mainAccountName = value.name
        } else {
            for (let i = 0; i < state.google.accounts.length; i++) {
                if (state.google.accounts[i].id === state.user.mainAccount) {
                    state.user.mainAccountName = state.google.accounts[i].name
                    break
                }
            }
        }
    },

    // Accounts
    UPDATE_MAIN_ACCOUNT(state, value) {
        state.user.mainAccount = value.id
        state.user.mainAccountName = value.name
    },

    // Accounts
    UPDATE_NOTIFICATIONS(state, value) {
        state.user.notiEnable = value.notiEnable
        state.user.notiFreq = value.notiFreq
    },

    // Accounts
    UPDATE_TRIAL_LIMIT(state, value) {
        state.trialLimit = value
    },

    UPDATE_SETUP_COMPLETED(state, value) {
        state.setupCompleted = value
    },

    setUser(state, user) {
        state.userAmpli = user
    },

    UPDATE_USER_RULES(state, value) {
        state.userRuleList = value
    },

    UPDATE_USER_RULE(state, value) {
        if (value.ruleType === 'add') {
            state.userRuleList.push(value)
        } else if (value.ruleType === 'edit') {
            const userRulesList = state.userRuleList
            for (let i = 0; i < userRulesList.length; i++) {
                if (value.id === userRulesList[i].id) {
                    userRulesList[i] = value
                }
            }
        }
    },

    REMOVE_USER_RULE(state, value) {
        const userRulesList = state.userRuleList
        for (let i = 0; i < userRulesList.length; i++) {
            if (value === userRulesList[i].id) {
                userRulesList.splice(i, 1)
            }
        }
    },

    ENABLE_USER_RULE(state, value) {
        const userRulesList = state.userRuleList
        for (let i = 0; i < userRulesList.length; i++) {
            if (value === userRulesList[i].id) {
                userRulesList[i].active = true
            }
        }
    },

    DISABLE_USER_RULE(state, value) {
        const userRulesList = state.userRuleList
        for (let i = 0; i < userRulesList.length; i++) {
            if (value === userRulesList[i].id) {
                userRulesList[i].active = false
            }
        }
    },

    STORE_STATS_RAW(state, value) {
        state.statsRaw = value
    },

    UPDATE_ADMIN(state, value) {
        state.user.isAdmin = value;
    },

    EMPTY_STATS(state) {
        state.google = {
            accounts: []
        }
        state.statistics = {
            adsClicks: {
                dataThisMonth: [],
                dataPrevMonth: [],
                numAdsClicksThisMonth: 0,
                numAdsClicksPrevMonth: 0
            },
            clickFraud: {
                data: [],
                numAvoidClicks: 0,
                numCost: 0
            },
            uniqueDevices: {
                data: [],
                numUniqueDevices: 0
            },
            analyzedIPs: {
                data: [],
                numAnalyzedIPs: 0
            },
            lockIPs: {
                data: [],
                numLockIPs: 0
            }
        }
        state.userRuleList = []
        state.statsRaw = {}
    },
    EMPTY_USER(state) {
        state.setupCompleted = false,
            state.userUpdated = false,
            state.isSidebarActive = true,
            state.breakpoint = null,
            state.sidebarWidth = 'default',
            state.bodyOverlay = false,
            state.sidebarItemsMin = false,
            state.trialLimit = '',
            state.trialTotalDays = 30,
            state.user = {
                id: '',
                name: '',
                trialEndDate: -1,
                mainAccount: '',
                mainAccountName: '',
                notiEnable: false,
                notiFreq: 'd',
                isTrialAccount: false,
                credit: 0.0,
                initialCredit: 0,
                timezone: '',
                isAdmin: false,
                tourEnabled: false,
                currencySymbol: '€',
                currencyName: 'EUR',
                creditCardValid: false,
                billingInformation: false,
                billingDetails: {},
                protectingFlag: {},
            },
            state.userAmpli = null,
            state.google = {
                accounts: []
            },
            state.statistics = {
                adsClicks: {
                    dataThisMonth: [],
                    dataPrevMonth: [],
                    numAdsClicksThisMonth: 0,
                    numAdsClicksPrevMonth: 0
                },
                clickFraud: {
                    data: [],
                    numAvoidClicks: 0,
                    numCost: 0
                },
                uniqueDevices: {
                    data: [],
                    numUniqueDevices: 0
                },
                analyzedIPs: {
                    data: [],
                    numAnalyzedIPs: 0
                },
                lockIPs: {
                    data: [],
                    numLockIPs: 0
                }
            },
            state.userRuleList = [],
            state.statsRaw = {},
            state.sales = {}
    },
    UPDATE_USER_ACCOUNTS(state, value) {
        state.google.accounts = value
    },
    UPDATE_CREDIT_CARD_VALID(state, value) {
        state.user.creditCardValid = value
    },
    UPDATE_BILL_INFORMATION(state, value) {
        state.user.billingInformation = value
    },
    UPDATE_BILL_DATA(state, value) {
        state.user.billingDetails = value
    },
    UPDATE_NOTI_ENABLE(state, value) {
        state.user.notiEnable = value
    },
    UPDATE_SALES_DATA(state, value) {
        state.sales = value
    },
    UPDATE_PROTECTING_FLAG(state, value) {
        state.user.protectingFlag.id = value.id
        state.user.protectingFlag.pay = value.pay
    },
    SET_EMAIL(state, value) {
        state.user.email = value
    },
    UPDATE_INTEGRATION_STEP(state, value) {
        state.user.integrationStep2 = value
    },
    UPDATE_TOUR_ENABLED(state, value) {
        state.user.tourEnabled = value
    },
}

export default mutations
