<template>
  <div v-bind:class="[showCookies ? cookiesVisible : '', cookiesContainer]">
    <div class="gdpr-cookie-layer__container">
      <p v-bind:class="[moreInfo ? extendedInfo : '', infoClass]">Utilizamos cookies y tecnologías similares propias y de terceros, de
        sesión o persistentes, para hacer funcionar de manera segura nuestra página web y personalizar su
        contenido. Igualmente, utilizamos cookies para medir y obtener datos de la navegación que realizas y
        para ajustar la publicidad a tus gustos y preferencias. Puedes configurar y aceptar el uso de
        cookies a continuación. Asimismo, puedes ver cómo modificar tus opciones de consentimiento en cualquier
        momento visitando nuestra <b>Política de Cookies</b>, haciendo clic <a href="https://www.clickdefense.io/cookies.html" target="_blank">aquí</a>.</p>
      <div class="gdpr-cookie-layer__lower-section">
        <div class="gdpr-cookie-layer__checkboxes">
          <div class="gdpr-cookie-layer__checkboxes__wrapper">
            <div class="gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--functional">
              <input type="checkbox" name="functional" value="functional" checked="" disabled="">
              <label for="functional">Técnicas</label>
            </div>
            <div class="gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--comfort">
              <input type="checkbox" name="comfort" value="comfort" id="comfort_id"> <label for="comfort_id">Personalización</label>
            </div>
            <div class="gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--statistical">
              <input type="checkbox" name="statistical" value="statistical" id="statistical_id">
              <label for="statistical_id">Analíticas</label>
            </div>
            <div class="gdpr-cookie-layer__checkboxes__input-group gdpr-cookie-layer__checkboxes__input-group--marketing">
              <input type="checkbox" name="marketing" value="marketing" id="marketing_id"> <label for="marketing_id">Marketing</label>
            </div>
          </div><button class="gdpr-cookie-layer__btn gdpr-cookie-layer__btn--details" @click="showInfo()">Más
            información<span v-bind:class="[moreInfo ? 'open' : '']"></span></button>
        </div>
        <div class="gdpr-cookie-layer__submit-buttons"><button class="gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--all" @click="acceptAll()"> ✔
            Aceptar
            todo</button><button class="gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--selected" @click="saveCookies()">Guardar</button>
        </div>
        <div id="botonera2">
          <div class="gdpr-cookie-layer__submit-buttons"><button class="gdpr-cookie-layer__btn gdpr-cookie-layer__btn--details" @click="showInfo()">Más
              información<span v-bind:class="[moreInfo ? 'open' : '']"></span></button><button class="gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--selected">Guardar</button>
          </div>
          <button class="gdpr-cookie-layer__btn gdpr-cookie-layer__btn--submit gdpr-cookie-layer__btn--submit--all" @click="acceptAll()"> ✔
            Aceptar
            todo</button>
        </div>
      </div>
      <div v-bind:class="[moreInfo ? detailShow : '', detailClass]">
        <div data-cms-id="gdpr_cookie_layer-content" class="contentspot">
          <div id="gdpr_cookielayer_page-content" class="fg-container fg-initialized" identifier="cookieInfoLayer" style="min-height: 0px;">
            <div class="fg-box bpx0 bpy0 bsx5 bsy1 mpx0 mpy0 msx4 msy1 spx0 spy0 ssx3 ssy1 fg-box-size-x-5" style="top: 0px; height: auto;">
              <div class="cms_html_container">
                <div class="cms_html_container">
                  <h6>Cookies técnicas</h6>
                  <p>Las cookies técnicas son esenciales para que te puedas mover por la
                    Plataforma y usar sus funciones. De manera general, estas cookies habilitan
                    las diferentes funciones de la Plataforma, hacen que tu navegación sea más
                    segura o aportan funcionalidades que son previamente solicitadas por ti. Al
                    ser necesarias para el funcionamiento del sitio, estas cookies por defecto
                    estarán activadas y no podrán denegarse.</p>
                  <p>ClickDefense puede utilizar cookies necesarias para guardar tu selección de
                    las
                    cookies en nuestro configurador, estabilizar tu navegación o hacerla más
                    segura. </p>
                  <table>
                    <thead>
                      <tr>
                        <td>Cookies técnicas</td>
                        <td>Proveedor</td>
                        <td>Finalidad</td>
                        <td>Caducidad</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>cdAccept</td>
                        <td>ClickDefense</td>
                        <td>Contiene la configuración de consentimiento en la página.</td>
                        <td>12 meses</td>
                      </tr>
                    </tbody>
                  </table>
                  <h6>Cookies de marketing</h6>
                  <p>Estas cookies son aquellas que almacenan información del comportamiento de
                    los usuarios obtenida a través de la observación continuada de sus hábitos
                    de navegación, lo que permite desarrollar un perfil específico para mostrar
                    publicidad en función de este o realizar determinados tratamientos y
                    acciones publicitarias. De igual manera, a través del uso de estas Cookies
                    podrás recibir anuncios de ClickDefense en sitios webs de terceros. Puedes
                    aceptar o rechazar la instalación de estas Cookies.</p>
                  <table>
                    <thead>
                      <tr>
                        <td>Cookies de marketing</td>
                        <td>Proveedor</td>
                        <td>Finalidad</td>
                        <td>Caducidad</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>_fbp</td>
                        <td>Facebook</td>
                        <td>Usado por Facebook para proporcionar servicios publicitarios y de
                          análisis del sitio web.</td>
                        <td>3 meses</td>
                      </tr>
                      <tr>
                        <td>fr</td>
                        <td>Facebook</td>
                        <td>Usado por Facebook para mostrar, medir y mejorar la relevancia de
                          anuncios.</td>
                        <td>3 meses</td>
                      </tr>
                    </tbody>
                  </table>
                  <h6>Cookies analíticas</h6>
                  <p>Estas Cookies permiten realizar un seguimiento y análisis del comportamiento
                    de los usuarios, incluida la cuantificación de los impactos de los anuncios.
                    La información recogida mediante este tipo de Cookies se utiliza en la
                    medición de la actividad de la Plataforma con el fin de introducir mejoras
                    en función del análisis de los datos de uso que hacen los usuarios del
                    servicio. Puedes aceptar o rechazar la instalación de estas Cookies.</p>
                  <table>
                    <thead>
                      <tr>
                        <td>Cookies analíticas</td>
                        <td>Proveedor</td>
                        <td>Finalidad</td>
                        <td>Caducidad</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>_gid</td>
                        <td>Google Analytics</td>
                        <td>Usado para distinguir usuarios. </td>
                        <td>1 día</td>
                      </tr>
                      <tr>
                        <td>_ga</td>
                        <td>Google Analytics</td>
                        <td>Usado para distinguir usuarios. </td>
                        <td>24 meses</td>
                      </tr>
                      <tr>
                        <td>_gat</td>
                        <td>Google Analytics</td>
                        <td>Se utiliza para reducir la tasa de solicitudes.</td>
                        <td>1 minuto</td>
                      </tr>
                      <tr>
                        <td>_fgSesionId</td>
                        <td>Froged</td>
                        <td>Usado para realizar seguimiento de cómo interactúa el usuario con el
                          sitio web.</td>
                        <td>6 meses</td>
                      </tr>
                    </tbody>
                  </table>
                  <br>
                  <p>Recuerda que puedes ver más información acerca de las cookies y cómo modificar tus
                    opciones de consentimiento en cualquier momento visitando nuestra <b>Política de
                      Cookies</b>, haciendo clic <a href="https://www.clickdefense.io/cookies.html" target="_blank">aquí</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      moreInfo: false,
      extendedInfo: 'gdpr-cookie-layer__info--noShow',
      infoClass: 'gdpr-cookie-layer__info',
      detailShow: 'gdpr-cookie-layer__details-section--show',
      detailClass: 'gdpr-cookie-layer__details-section',
      showCookies: false,
      cookiesContainer: 'gdpr-cookie-layer',
      cookiesVisible: 'gdpr-cookie-layer--show',
      maketing: false,
      analytics: false,
    }
  },
  created() {
    if (this.$cookies.isKey('cdAccept')) {
      // if (this.$cookies.get('cdAccept') === '1') {
      //   this.showCookies = true
      // } else {
      //   this.showCookies = false
      // }
      if (this.$cookies.get('cdAccept') !== '24') {
        this.$cookies.set("cdAccept", "24", "12m", null, null, true, "None")
        this.$gtm.push({ event: 'Activar_Cookies' })
      }
    } else {
      // SHOW COOKIES 
      // this.showCookies = true
      // this.$cookies.set("cdAccept", "1", "12m", null, null, true, "None")
      this.$cookies.set("cdAccept", "24", "12m", null, null, true, "None")
      this.$gtm.push({ event: 'Activar_Cookies' })
    }
  },
  methods: {
    showInfo() {
      this.moreInfo = !this.moreInfo
    },
    acceptAll() {
      this.$cookies.set("cdAccept", "2345", "12m", null, null, true, "None")
      this.showCookies = false
    },
    saveCookies() {
      if (!this.analytics && !this.marketing) {
        this.$cookies.set("cdAccept", "2", "12m", null, null, true, "None")
      } else if (this.analytics && this.marketing) {
        this.$cookies.set("cdAccept", "2345", "12m", null, null, true, "None")
      } else if (this.analytics) {
        this.$cookies.set("cdAccept", "24", "12m", null, null, true, "None")
      } else {
        this.$cookies.set("cdAccept", "25", "12m", null, null, true, "None")
      }
      this.showCookies = false
    }
  }
}
</script>