import Vue from 'vue'
import App from './App.vue'

// Amplify
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsconfig from './aws-exports'
Amplify.configure(awsconfig)
Vue.use(AmplifyPlugin, AmplifyModules)

// Api
import AxiosPlugin from '@/api/axios.js'
Vue.use(AxiosPlugin, '$axios')

// UUID
import UUID from 'vue-uuid'
Vue.use(UUID)

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

// Load scripts
import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'
import './assets/scss/bootstrap-vue.scss'


// Tailwind
import '@/assets/css/main.css';

// Cookies
import '@/assets/css/cookies.css';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// Vue Router
import router from './router'


// Vuex Store
import store from './store'

// Vue-clipboard2
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  fieldsBagName: 'vvFields'
})

// Vue Tag Manager
import VueTagManager from "vue-tag-manager"

Vue.use(VueTagManager, {
  gtmId: 'GTM-MH32NN8'
})

// Feather font icon
require('./assets/css/iconfont.css')

// Vue-Acl
import acl from './acl'

//Vue-Social-Sharing 
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

// Vue-Unicons
import Unicon from 'vue-unicons'
import { uniWallet, uniFileDownload, uniTimesCircle, uniCheckCircle } from 'vue-unicons/src/icons'
Unicon.add([uniWallet, uniFileDownload, uniTimesCircle, uniCheckCircle])
Vue.use(Unicon)

// Vue-Plugin ChargeData
import ChargeData from './plugins/ChargeData'
Vue.use(ChargeData)

//Vue credit-card-validation
import VueCardFormat from 'vue-credit-card-validation'
Vue.use(VueCardFormat)

// Vue Crypt
import VueCryptojs from 'vue-cryptojs'
Vue.use(VueCryptojs)

Vue.config.productionTip = false

window.frogedSettings = { appId: "3woshj" }
// eslint-disable-next-line no-undef
Froged('boot')


new Vue({
  router,
  store,
  acl,
  render: h => h(App)
}).$mount('#app')