import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from './router'

Vue.use(AclInstaller)

export default new AclCreate({
  initial: 'user',
  notfound: {
    path: '/pages/error-404',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isAdminAcl: new AclRule('admin').generate(),
    isRefererAcl: new AclRule('referer').or('admin').generate(),
    isSalesAcl: new AclRule('sales').or('admin').generate(),
    isPaymentAcl: new AclRule('payment').or('referer').or('admin').generate(),
    isUserAcl: new AclRule('user').or('payment').or('referer').or('admin').generate(),
  }
})