import { EventBus } from '@/event-bus'
import Amplify from 'aws-amplify'

const ChargeData = {
  install(vue) {
    vue.prototype.$composeUserData = function (dataUser) {
      let accountsList = []
      let userRuleList = []
      let lastMainAccount = ''
      let lastMainAccountName = ''

      if (!this.$store.state.user.isAdmin) {
        if (!dataUser.timestamp) {
          this.$acl.change('user')
        } else if (dataUser.timestamp === 6406939373) {
          this.$acl.change('payment')
        } else if (dataUser.timestamp === 7830028201) {
          this.$acl.change('referer')
        } else if (dataUser.timestamp === 9466848005) {
          this.$acl.change('user')
        } else if (dataUser.timestamp === 111122223333) {
          this.$acl.change('sales')
        }
      }
      if (!this.$store.state.user.isAdmin && dataUser.timestamp === 111122223333) {
        let salesAccount = {}
        for (const valueAccount of Object.entries(dataUser.salesAccounts)) {
          let account = {
            accountName: '',
            startDate: '',
            payGoogle: '',
            monitoring: '',
            monitoringDate: '',
            protecting: '',
            protectingDate: '',
          }
          account.accountName = valueAccount.accountName
          account.startDate = valueAccount.startDate
          account.payGoogle = valueAccount.payGoogle
          account.monitoring = valueAccount.monitoring
          account.monitoringDate = valueAccount.monitoringDate
          account.protecting = valueAccount.protecting
          account.protectingDate = valueAccount.protectingDate

          salesAccount.push(account)
        }
        this.$store.dispatch('updateSalesData', salesAccount)
        EventBus.$emit('update-sales-screen', this.$store.state.userAmpli.attributes.name)
        EventBus.$emit('user-update-name', this.$store.state.userAmpli.attributes.name)
        // Redirigir al final a la pestaña de ventas
      } else {
        let accMonitoring = 0
        for (const [keyAccount, valueAccount] of Object.entries(dataUser.accounts)) {
          let account = {
            mainAccount: valueAccount.mainAccount,
            name: valueAccount.name,
            monitoring: valueAccount.monitoring,
            protecting: valueAccount.protecting,
            id: keyAccount,
            campaigns: [],
            nCampaigns: 0
          }
          lastMainAccount = keyAccount
          lastMainAccountName = valueAccount.name
          let campaigns = []
          for (const [keyCampaign, valueCampaign] of Object.entries(valueAccount.campaigns)) {
            const campaign = {
              name: valueCampaign.name,
              network: valueCampaign.network.toLowerCase(),
              status: valueCampaign.status.toLowerCase(),
              id: keyCampaign
            }
            campaigns.push(campaign)
          }
          account.campaigns = campaigns
          account.nCampaigns = campaigns.length
          accountsList.push(account)
          accMonitoring++
        }
        accountsList.sort(function (a, b) { return a.monitoring - b.monitoring })
        accountsList.sort(function (a, b) { return a.protecting - b.protecting })
        accountsList.reverse()
        // INI User data
        if (!dataUser.mainAccount && !this.$store.state.user.isAdmin) {
          dataUser.mainAccount = lastMainAccount
          dataUser.mainAccountName = lastMainAccountName
          Amplify.Auth.currentSession().then(apiSession => {
            // INI Set user mainAccount
            const paramsUser = {
              mainAccount: dataUser.mainAccount,
              mainAccountName: dataUser.mainAccountName
            }
            this.$axios.put('/user/0', paramsUser, {
              headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
            })
            // FIN Set user mainAccount
          })
        }
        let isMonitoring = false
        if (accMonitoring) {
          isMonitoring = true
        }
        this.$store.dispatch('updateUserData', {
          name: this.$store.state.userAmpli.attributes.name || '',
          mainAccount: dataUser.mainAccount || '',
          mainAccountName: dataUser.mainAccountName || '',
          notiEnable: dataUser.notiEnable,
          notiFreq: dataUser.notiFreq || 'd',
          isTrialAccount: dataUser['isTrial'],
          trialEndDate: dataUser['trialEnd'] || -1,
          credit: dataUser.credit || 0,
          initialCredit: dataUser.initialCredit || 0,
          tourEnabled: dataUser.tourEnabled || false,
          currencySymbol: dataUser.currencySymbol || '€',
          currencyName: dataUser.currencyName || 'EUR',
          creditCardValid: dataUser.creditCardValid || false,
          billingInformation: dataUser.billingInformation || false,
          billingDetails: dataUser.billingDetails || {},
          protectingFlag: {
            id: 0,
            pay: false,
          },
          integrationStep2: isMonitoring,
          pixelv2Enabled: dataUser.pixelv2Enabled || false,
          id: dataUser.userID || ''
        })
        EventBus.$emit('user-update-name', this.$store.state.userAmpli.attributes.name)
        EventBus.$emit('user-currencyName', this.$store.state.user.currencyName)
        EventBus.$emit('user-currencySymbol', this.$store.state.user.currencySymbol)
        EventBus.$emit('user-creditCardValid', this.$store.state.user.creditCardValid)
        EventBus.$emit('user-get-credit', this.$store.state.user.credit)
        EventBus.$emit('update-billingInformation', this.$store.state.user.billingInformation)
        EventBus.$emit('update-billingDetails', this.$store.state.user.billingDetails)
        EventBus.$emit('update-notiEnable', this.$store.state.user.notiEnable)
        EventBus.$emit('update-integrationStep', this.$store.state.user.integrationStep2)
        EventBus.$emit('update-pixelEnabled', this.$store.state.user.pixelv2Enabled)
        // FIN User data
        this.$store.dispatch('updateAccounts', accountsList)
        const paramsUserData = {
          accountsList: accountsList
        }
        EventBus.$emit('user-update-accounts', paramsUserData)
        for (const [keyUserRul, valueUserRul] of Object.entries(dataUser.userRules)) {
          const userRule = {
            active: valueUserRul.active,
            delAfter: valueUserRul.delAfter,
            delAfterUnit: valueUserRul.delAfterUnit,
            id: valueUserRul.id,
            limit: valueUserRul.limit,
            name: valueUserRul.name,
            time: valueUserRul.time,
            timeUnit: valueUserRul.timeUnit,
            type: valueUserRul.type,
            key: keyUserRul
          }
          if (this.$store.state.user.mainAccount === valueUserRul.gAccount) {
            userRuleList.push(userRule)
          }
        }
        this.$store.dispatch('updateUserRules', userRuleList)
      }
    },
      vue.prototype.$initMainData = async function (dataUserId) {
        return new Promise((resolve, reject) => {
          Amplify.Auth.currentSession().then(apiSession => {
            if (!this.$store.state.userUpdated) {
              // INI Get user
              this.$axios.get(`/user/${dataUserId}`, {
                headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
              }).then(response => {
                this.$composeUserData(response.data)
                resolve(response.data)
                return
              }).catch(err => {
                reject('NOK' + err)
              })
              // FIN Get user
            } else {
              EventBus.$emit('user-update-name', this.$store.state.userAmpli.attributes.name)
              EventBus.$emit('user-currencyName', this.$store.state.user.currencyName)
              EventBus.$emit('user-currencySymbol', this.$store.state.user.currencySymbol)
              EventBus.$emit('user-creditCardValid', this.$store.state.user.creditCardValid)
              EventBus.$emit('user-get-credit', this.$store.state.user.credit)
              EventBus.$emit('update-billingInformation', this.$store.state.user.billingInformation)
              EventBus.$emit('update-billingDetails', this.$store.state.user.billingDetails)
              EventBus.$emit('update-notiEnable', this.$store.state.user.notiEnable)
              EventBus.$emit('update-integrationStep', this.$store.state.user.integrationStep2)
              EventBus.$emit('update-pixelEnabled', this.$store.state.user.pixelv2Enabled)
              resolve(false)
              return
            }
          })
        })
      }
  }
}

export default ChargeData 
