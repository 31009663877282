import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

const state = {
  setupCompleted: false,
  userUpdated: false,
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: 'default',
  reduceButton: themeConfig.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  theme: themeConfig.theme || 'light',
  trialLimit: '',
  trialTotalDays: 15,
  user: {
    id: '',
    name: '',
    trialEndDate: -1,
    mainAccount: '',
    mainAccountName: '',
    notiEnable: false,
    notiFreq: 'd',
    isTrialAccount: true,
    credit: 0.0,
    initialCredit: 0,
    timezone: '',
    isAdmin: false,
    tourEnabled: false,
    googleCredit: 0.0,
    currencySymbol: '€',
    currencyName: 'EUR',
    creditCardValid: false,
    billingInformation: false,
    billingDetails: {},
    protectingFlag: {
      id: 0,
      pay: false,
    },
    email: ''
  },
  userAmpli: null,
  themePrimaryColor: colors.primary,
  google: {
    accounts: []
  },
  statistics: {
    adsClicks: {
      dataThisMonth: [],
      dataPrevMonth: [],
      numAdsClicksThisMonth: 0,
      numAdsClicksPrevMonth: 0
    },
    clickFraud: {
      data: [],
      numAvoidClicks: 0,
      numCost: 0
    },
    uniqueDevices: {
      data: [],
      numUniqueDevices: 0
    },
    analyzedIPs: {
      data: [],
      numAnalyzedIPs: 0
    },
    lockIPs: {
      data: [],
      numLockIPs: 0
    }
  },
  userRuleList: [],
  statsRaw: {},
  sales: {}
}

export default state
