<template>
  <div id="app">
    <router-view></router-view>
    <cookies-container></cookies-container>
  </div>
</template>

<script>
import { components } from 'aws-amplify-vue'
import themeConfig from "@/../themeConfig.js"
import CookiesContainer from './components/CookiesContainer.vue'

export default {
  components: {
    CookiesContainer,
    ...components
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val)
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark")
        document.body.classList.add("theme-dark")
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark")
        document.body.classList.add("theme-semi-dark")
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark")
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark")
      }
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$axios.defaults.baseURL = 'https://pfuua668xu8xkdtcag4h.clickdefense.cc/v1'
  }
}
</script>

